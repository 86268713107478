import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { config } from 'src/config';
import { AuthResponse } from './appInterface/auth-responce-interface';
import { UserResponse } from './appInterface/user-responce-interface';
import { Subject , BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { User } from './appModels/user-model';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService 
{
  private user: BehaviorSubject<User>;
  constructor(private http:HttpClient, private router:Router)
   { 
    this.user = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('UserData')));
    //console.log(this.getcurrentUserValue());
   }
   signUp(name,email)
   {
      return this.http.post<AuthResponse>(`${config.BASE_URL}web/doorbell/unsigned/register`,{
        uname:name,
        email:email
      });
   }
   signIn(email,upass)
   {
    return this.http.get<UserResponse>(`${config.BASE_URL}web/doorbell/unsigned/login`,
      {
        params: new HttpParams()
        .set('email',email)
        .set('upass',upass)
      }
      ).pipe(
      tap(res=>{
        if(res.status==true)
        {
          console.log(res.content['user']['utype']);
          if(res.content['user']['utype']=="A")
          {
           
            this.authUser(+res.content['user']['id'],res.content['user']['utype'],res.content['user']['email']
            ,res.content['user']['ukey'],res.content['user']['uname']);
          }
          
        }
        else
        {
          return;
        }
      })
      
    );
   }
  authUser(id,utype,email,ukey,name,bio?,occupation?,website?,location?)
   {
     let p_user;
     if(utype=="A")
     {
      p_user = new User(id,utype,email,ukey,name);
     }
      this.user.next(p_user);
      localStorage.setItem("UserData",JSON.stringify(p_user));
   }
   authLogin()
   {
     const userData=JSON.parse(localStorage.getItem('UserData'));
     let loggedinUser;
     if(!userData)
     {
        return;
     }
     if(userData.utype=="A")
     {
      loggedinUser= new User(userData.id,userData.utype,userData.email,userData.ukey,userData.name);
     }
     this.user.next(loggedinUser);
   }
   signOut()
   {
    this.user.next(null);
    //localStorage.clear();
    localStorage.removeItem('UserData');
    this.router.navigate(['/u/auth/login']);
    
   }
   public get currentUserValue(): User 
   {
    return this.user.value;
    } 
    forgetPasswd(email)
    {
      return this.http.get<AuthResponse>(`${config.BASE_URL}web/doorbell/unsigned/forget-password`,{
        params: new HttpParams()
        .set('email',email)
      });
    }


  store(mac_address,ukey,user_id)
   {
     let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.post<UserResponse>(`${config.BASE_URL}web/doorbell/signed/bells/add`,{
        mac_address:mac_address,
      },{headers:headers
      });
   }

   show(ukey,user_id)
   {
    let headers= new HttpHeaders({
      'Content-Type':'application/json',
      'X-Api-Key':ukey
     });

      return this.http.get<UserResponse>(`${config.BASE_URL}web/doorbell/signed/bells/list`, 
      {
        headers:headers
      });
   }


}
