import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { UnsignedComponent } from './unsigned/unsigned.component';
import { AuthComponent } from './unsigned/auth/auth.component';
import { LoginComponent } from './unsigned/auth/login/login.component';
import { RegisterComponent } from './unsigned/auth/register/register.component';
import { ForgetpassComponent } from './unsigned/auth/forgetpass/forgetpass.component';
import { SignedComponent } from './signed/signed.component';
import { P404Component } from './unsigned/p404/p404.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';




@NgModule({
  declarations: [
    AppComponent,
    UnsignedComponent,
    AuthComponent,
    LoginComponent,
    RegisterComponent,
    ForgetpassComponent,
    SignedComponent,
    P404Component

  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule
    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
