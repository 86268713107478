<section id="login" class="auth-height">
    <div class="row full-height-vh m-0">
        <div class="col-12 d-flex align-items-center justify-content-center">
            <div class="card overflow-hidden">
                <div class="card-content">
                    <div class="card-body auth-img">
                        <div class="row m-0">
                            <!-- <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                                <img src="../../../assets/app-assets/img/gallery/login.png" alt="" class="img-fluid" width="300" height="230">
                            </div> -->
                            <div class="col-lg-12 col-12 px-4 py-3">
                                <h4 class="mb-2 card-title">Login</h4>
                                <p>Welcome back, please login to your account.</p>
                                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                <input type="email" class="form-control mb-3" formControlName="email" placeholder="Username"  [ngClass]="{ 'is-invalid': submitted && f.email.errors }" required>
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">Username is required</div>
                                </div>
                                <input type="password" formControlName="upass" class="form-control mb-2" placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.upass.errors }" required>
                                <div *ngIf="submitted && f.upass.errors" class="invalid-feedback">
                                    <div *ngIf="f.upass.errors.required">Password is required</div>
                                </div>
                                <div class="d-sm-flex justify-content-between mb-3 font-small-2">
                                    <div class="remember-me mb-2 mb-sm-0">
                                        <!-- <div class="checkbox auth-checkbox">
                                            <input type="checkbox" id="auth-login">
                                            <label for="auth-ligin"><span>Remember Me</span></label>
                                        </div> -->
                                    </div>
                                    <a class="text-info" routerLink="/u/auth/forgetpass">Forgot Password?</a>
                                </div>
                                <div class="d-flex justify-content-between flex-sm-row flex-column">
                                    <!-- <a routerLink="/u/auth/register" class="btn btn-secondary mb-2 mb-sm-0">Register</a> -->
                                    <button [disabled]="loading" class="btn btn-info">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Login</button>
                                </div>
                            </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>