import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators ,FormControl} from '@angular/forms';
import { AuthService } from 'src/app/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;

  constructor(private fb:FormBuilder, 
    private _authservice:AuthService,
    private router: Router) 
    {
      const user_val= this._authservice.currentUserValue;
      if (user_val) 
      {
        //console.log(user_val.utype);
        if(user_val.utype =="A" )
        {
          this.router.navigate(['/s/a']);
        }
        else
        {
          this.router.navigate(['/']);
        }
      }
     }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl(),
      utype: new FormControl()
   });

   this.loginForm = this.fb.group({
    email: ['', Validators.required],
    upass: ['', Validators.required]
});
  }
  get f() { return this.loginForm.controls; }

  onSubmit()
  {

    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }

    this.loading = true;

    // console.log(this.loginForm.value)
    const email=this.loginForm.value.email;
    const upass=this.loginForm.value.upass;
    this._authservice.signIn(email,upass).subscribe(
      res=>
      {
        this.loading = false;
        if(res.status==true)
        {
          if(res.content['user']['utype'] == "A" )
          {
            this.router.navigate(['/s/a']);
          }
          else
          {
            this.router.navigate(['/']);
          }
        }
        else
        {
          alert(res.message)
        }
       
      },
      err=>
      {
        this.loading = false;
      }
    );
  

  }
}
